<template>
    <div>
        <Loader v-if="signPending" />
        <div v-if="showReviewLabels" :class="`label label-lg label-inline label-light-success ml-6`">
            {{ firstStaffReviewText }}
        </div>
        <div v-if="showReviewLabels" :class="`label label-lg label-inline label-light-success ml-6`">
            {{ lastStaffReviewText }}
        </div>
        <div v-if="showReviewLabels" :class="`label label-lg label-inline label-light-warning ml-6`">
            {{ firstCitizenReviewText }}
        </div>
        <div v-if="showReviewLabels" :class="`label label-lg label-inline label-light-warning ml-6`">
            {{ lastCitizenReviewText }}
        </div>

        <st-button
            v-if="canUpload"
            variant="primary"
            :callback="addDocument"
            class="ml-6"
        >
            <i class="fa fa-plus"></i>
            {{ $t('APPLICATION.LIST.ADD_DOCUMENT_BUTTON') }}
        </st-button>

        <st-button
            variant="primary"
            :callback="refresh"
            class="ml-6"
        >
            <i class="fas fa-sync"></i>
            {{ $t('APPLICATION.LIST.REFRESH_DOCUMENT_BUTTON') }}
        </st-button>

        <st-button
            v-if="showBtn"
            :disabled="!canBeSigned"
            variant="primary"
            :callback="signBulkAttachments"
            class="ml-6"
        >
            <i class="fa fa-save"></i>
            {{ $t('APPLICATION.VIEW.SIGN_ATTACHMENTS_BUTTON') }}
        </st-button>

        <st-button
            v-if="showBtn"
            :disabled="!canBeSealed"
            variant="primary"
            :callback="sealBulkAttachments"
            class="ml-6"
        >
            <i class="fa fa-save"></i>
            {{ $t('APPLICATION.VIEW.SEAL_ATTACHMENTS_BUTTON') }}
        </st-button>

        <st-button
            :disabled="!canDownloadArchive"
            variant="primary"
            :callback="downloadCompression"
            class="ml-6"
        >
            <i class="fa fa-file-archive"></i>
            {{ $t('APPLICATION.VIEW.DOWNLOAD_COMPRESSION') }}
        </st-button>

        <st-button
            v-if="canBulkCopy"
            variant="primary"
            :callback="showBulkCopyFilesModal"
            class="ml-6"
        >
            <i class="fa fa-file-archive"></i>
            {{ $t('GENERAL.ACTIONS.COPY') }}
        </st-button>

        <application-documents-upload-modal
            ref="documents-upload-modal"
            :applicationId="applicationId"
            :isPublic="isPublic"
            @uploaded="uploaded"
        >
        </application-documents-upload-modal>

        <application-attachments-sign-modal
            ref="attachments-sign-modal"
            :applicationId="applicationId"
            :fileIds="fileIds"
            @refresh="refresh"
        >
        </application-attachments-sign-modal>

        <application-bulk-copy-files-modal
            ref="bulk-copy-file-modal"
            :files="allFiles"
        >
        </application-bulk-copy-files-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
    import Message from '@/shared/message/message';
    import moment from "moment";
    import { axiosInlineDownload } from '@/modules/applications/utils/axios-inline-downloader';

    export default {
        name: 'ApplicationDocumentsListToolbar',
        components: {
            ApplicationDocumentsUploadModal: () => import("./ApplicationDocumentsUploadModal.vue"),
            ApplicationAttachmentsSignModal: () => import("./ApplicationAttachmentsSignModal.vue"),
            ApplicationBulkCopyFilesModal: () => import("./ApplicationBulkCopyFilesModal.vue")
        },
        props: {
            isPublic: {
                type: Boolean,
                default: false
            },
            canSign: {
                type: Boolean,
                default: false
            },
            canBulkCopy: {
                type: Boolean,
                default: false
            },
            fileIds: {
                type: Array,
                default: () => []
            },
            allFiles: {
                type: Array,
                default: () => []
            },
            disableUpload: {
                type: Boolean,
                default: false
            },
            documentType: String,
            title: String,
        },
        data() {
            return {
                cannotSignStatus: ['rejected', 'signed', 'sealed'],
                signPending: false
            }
        },
        computed: {
            ...mapGetters({
                currentUser: 'auth/currentUser',
                record: 'applications/form/record',
                compressionFileToken: 'applications/form/compressionFileToken',
            }),
            applicationId() {
                return this.record.id;
            },
            canUpload() {
                return !this.disableUpload && this.hasPermissionToReadStaff && !['rejected'].includes(this.record.status);
            },
            hasPermissionToOrganisationsApplication() {
                return new ApplicationsPermissions(this.currentUser).readStaff;
            },
            hasPermissionToStaffOwnApplications() {
                return new ApplicationsPermissions(this.currentUser).readOwnStaff;
            },
            hasPermissionToReadStaff() {
                return (
                    this.hasPermissionToOrganisationsApplication ||
                    this.hasPermissionToStaffOwnApplications
                );
            },
            canBeSigned() {
                //add condition for at least one attachment to be selected
                return this.hasPermissionToReadStaff && this.canSign
                    && this.record?.assigned_staff_user_id
                    && !this.cannotSignStatus.includes(this.record.internal_status);
            },
            showReviewLabels() {
                return this.documentType === 'review';
            },
            firstCitizenReviewText() {
                const value = this.record.first_citizen_review_date;
                const format = value ? moment(value).format('DD-MM-YYYY HH:mm') : '-';
                return `${this.$t('APPLICATION.INFO_TEXT.FIRST_CITIZEN_REVIEW_DATE')}: ${format}`;
            },
            lastCitizenReviewText() {
                const value = this.record.last_citizen_review_date;
                const format = value ? moment(value).format('DD-MM-YYYY HH:mm') : '-';
                return `${this.$t('APPLICATION.INFO_TEXT.LAST_CITIZEN_REVIEW_DATE')}: ${format}`;
            },
            firstStaffReviewText() {
                const value = this.record.first_staff_review_date;
                const format = value ? moment(value).format('DD-MM-YYYY HH:mm') : '-';
                return `${this.$t('APPLICATION.INFO_TEXT.FIRST_STAFF_REVIEW_DATE')}: ${format}`;
            },
            lastStaffReviewText() {
                const value = this.record.last_staff_review_date;
                const format = value ? moment(value).format('DD-MM-YYYY HH:mm') : '-';
                return `${this.$t('APPLICATION.INFO_TEXT.LAST_STAFF_REVIEW_DATE')}: ${format}`;
            },
            canDownloadArchive() {
                return !!this.allFiles.length;
            },
            canBeSealed() {
                //add condition for at least one attachment to be selected
                const sealError = this.allFiles.some((el) => el.status === 'seal_error');
                return this.record.status === 'closed' &&
                    this.hasPermissionToReadStaff &&
                    this.record?.assigned_staff_user_id &&
                    sealError;
            },
            showBtn() {
                return this.documentType !== 'review';
            },
        },
        methods: {
            ...mapActions({
                doSignAttachmentsBulk: "applications/form/doSignAttachmentsBulk",
                doDownloadCompression: "applications/form/doDownloadCompression",
                doFind: "applications/form/find",
                doSealAttachmentsBulk: "applications/form/doSealAttachmentsBulk",
            }),
            addDocument() {
                this.$refs['documents-upload-modal'].show();
            },
            signBulkAttachments() {
                const payload = {
                    application_id: this.applicationId,
                    file_ids: this.fileIds.map((el) => el.file_id)
                }

                this.signPending = true;

                this.doSignAttachmentsBulk(payload).then((response) => {
                    this.signPending = false;
                    this.$refs['attachments-sign-modal'].show(response);
                }).catch(() => {
                    this.signPending = false;
                    Message.error('APPLICATION.VIEW.SIGN_DOCUMENT_ERROR');
                });
            },
            downloadCompression() {
                const payload = {
                    application_id: this.applicationId,
                    files: this.allFiles.map((el) => ({ id: el.file_id, filename: el.filename }))
                }
                const archiveName = `${this.record.identification_number} - ${this.title}`;
                this.signPending = true;
                this.doDownloadCompression(payload).then(async (response) => {
                    this.signPending = false;
                    await axiosInlineDownload({
                        url: `${window.VUE_APP_API_URL}/files/${response.file_id}?token=${this.compressionFileToken}`,
                        label: `${archiveName}.zip`,
                        type: 'application/zip',
                    });
                }).catch(() => {
                    this.signPending = false;
                    Message.error('ERRORS.ERROR_DOWNLOAD_COMPRESSION');
                });
            },
            async refresh() {
                setTimeout(async () => await this.doFind({id: this.record.id, loadStaff: true}), 500);
            },
            uploaded() {
                this.$emit("refresh");
            },
            sealBulkAttachments() {
                this.signPending = true;

                this.doSealAttachmentsBulk(this.applicationId).then((response) => {
                    this.signPending = false;
                }).catch(() => {
                    this.signPending = false;
                    Message.error('APPLICATION.VIEW.SEAL_DOCUMENT_ERROR');
                });
            },
            showBulkCopyFilesModal() {
                this.$refs['bulk-copy-file-modal'].show();
            },
        },
    };
</script>
