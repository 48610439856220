import { render, staticRenderFns } from "./ApplicationDocumentsListTableCitizen.vue?vue&type=template&id=78a631d2&"
import script from "./ApplicationDocumentsListTableCitizen.vue?vue&type=script&lang=js&"
export * from "./ApplicationDocumentsListTableCitizen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports