import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumField from '@/shared/fields/enum-field';

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`APPLICATION.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`APPLICATION.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    created_by: new StringField('created_by', label('created_by'), {}, { placeholder: placeholder('created_by')}),
    identification_number: new StringField('identification_number', label('identification_number'), {}),
    file_type: new StringField('file_type', label('file_type'), {}),
    territorial_administrative_unit: new StringField('territorial_administrative_unit', label('territorial_administrative_unit')),
    created_date: new DateTimeField('created_date', label('created_date')),
    resolved_date: new DateTimeField('resolved_date', label('resolved_date')),
    status: new EnumField(
        'status',
        label('status'),
        [
            {
                value: 'new',
                name: 'new',
                text: enumLabel('status', 'new'),
                type: 'primary',
            },
            {
                value: 'pending',
                name: 'pending',
                text: enumLabel('status', 'pending'),
                type: 'warning',
            },
            {
                value: 'processed',
                name: 'processed',
                text: enumLabel('status', 'processed'),
                type: 'success',
            },
            {
                value: 'resolved',
                name: 'resolved',
                text: enumLabel('status', 'resolved'),
                type: 'success',
            },
            {
                value: 'rejected',
                name: 'rejected',
                text: enumLabel('status', 'rejected'),
                type: 'danger',
            },
            {
                value: 'draft',
                name: 'draft',
                text: enumLabel('status', 'draft'),
                type: 'dark',
            },
            {
                value: 'generated',
                name: 'generated',
                text: enumLabel('status', 'generated'),
                type: 'info',
            },
            {
                value: 'signed',
                name: 'signed',
                text: enumLabel('status', 'signed'),
                type: 'info',
            },
            {
                value: 'sign_pending',
                name: 'sign_pending',
                text: enumLabel('status', 'sign_pending'),
                type: 'warning',
            },
            {
                value: 'error',
                name: 'error',
                text: enumLabel('status', 'error'),
                type: 'danger',
            },
            {
                value: 'sealed',
                name: 'sealed',
                text: enumLabel('status', 'sealed'),
                type: 'info',
            },
            {
                value: 'seal_pending',
                name: 'seal_pending',
                text: enumLabel('status', 'seal_pending'),
                type: 'warning',
            },
            {
                value: 'seal_error',
                name: 'seal_error',
                text: enumLabel('status', 'seal_error'),
                type: 'danger',
            },
        ],
        {},
        {
            placeholder: placeholder('status'),
        }
    ),
    filename: new StringField('filename', label('filename'), {}),
};

export class DocumentModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
