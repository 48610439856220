<template>
    <div>
        <st-modal
            ref='st-copy-file-modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="xs"
            :title="$t('APPLICATION.COPY_FILE_MODAL.TITLE')"
            customClass="form-modal"
            :id="modalId"
        >
            <template #body>
                <div>
                    <div class="form-group">
                        <st-input-text
                            v-model="filename"
                        />
                    </div>
                    <st-button
                        variant="primary"
                        :callback="setFilename">
                        {{ $t('GENERAL.BUTTON.SAVE') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: 'ApplicationCopyFileModal',
    data() {
        return {
            filename: '',
            modalId: `st-modal-copy-file-${generateRandomId()}`
        }
    },
    methods: {
        show(data) {
            this.filename = data;
            this.$refs['st-copy-file-modal'].show();
        },
        hide(){
            this.$refs['st-copy-file-modal'].hide();
        },
        setFilename() {
            this.$emit('setFilename', this.filename);
            this.hide();
        }
    },
}
</script>
