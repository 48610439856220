<template>
    <div>
        <st-data-table
            headClass="st-data-table-head"
            :items="files"
            :fields="fields"
            :actions="actions"
            :per-page="100"
            :modelPresenter="presenter"
            @download="onDownload"
            @view-doc="onViewDoc"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DocumentModel } from '@/modules/applications/models/document-model';

const { fields } = DocumentModel;

export default {
    name: 'ApplicationDocumentsListTableCitizen',
    components: {
    },
    props: {
        documentType: String,
    },
    data() {
        return {
            presenter: DocumentModel.presenter,
        };
    },
    computed: {
        ...mapGetters({
            fileToken: 'applications/form/fileToken',
            combinedFiles: 'applications/form/combinedFiles',
        }),
        fields() {
            return [
                fields.created_by,
                fields.filename,
                fields.created_date,
                fields.status.extendField({ labelType: true }),
            ]
        },
        actions() {
            return [
                {
                    name: 'view-doc',
                    icon: 'file-contract',
                    tooltipText: this.$t('GENERAL.ACTIONS.VIEW_ORIGINAL'),
                    customIcon: false,
                    type: 'primary'
                },
                {
                    name: 'download',
                    icon: 'file-download',
                    tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                    customIcon: false,
                    type: 'primary'
                },
            ];
        },
        files() {
            return this.combinedFiles.filter(file => file.file_type === this.documentType);
        },
    },
    methods: {
        ...mapActions({
            downloadDocument: 'applications/form/downloadDocument',
            downloadFile: 'shared/downloadFile',
            downloadFileContent: 'shared/downloadFileContent',
        }),
        onDownload(doc){
            const docName = doc.item.filename;
            const fileName = docName.includes(".") ? docName : `${docName}.pdf`;
            const url = `${window.VUE_APP_API_URL}/files/${doc.item.file_id}`;
            this.downloadFile({ url, fileName, token: this.fileToken });
        },
        async onViewDoc(doc) {
            //find other solution to avoid 431 header error if token as param is too large
            const url = `${window.VUE_APP_API_URL}/files/${doc.item.file_id}`;
            const fileContent = await this.downloadFileContent({url, token: this.fileToken});

            const _url = window.URL.createObjectURL(fileContent);
            window.open(_url, "_blank").focus();

            // window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileToken}`, '_blank');
        },
    },
};
</script>
