<template>
    <st-page :showHeader="false">
        <application-documents-list-filter :documentType="documentType">
            <application-documents-list-table-citizen
                :documentType="documentType"
            />
        </application-documents-list-filter>
    </st-page>
</template>

<script>
import ApplicationDocumentsListFilter from './ApplicationDocumentsListFilter';
import ApplicationDocumentsListTableCitizen from './ApplicationDocumentsListTableCitizen';

export default {
    name: 'ApplicationDocumentsCitizen',
    props: {
        documentType: String,
    },
    components: {
        ApplicationDocumentsListFilter,
        ApplicationDocumentsListTableCitizen,
    },
}
</script>
