<template>
    <st-page layout="tab" :showHeader="false" customClass="doc-layout">
        <application-documents-list></application-documents-list>
    </st-page>
</template>

<script>
import ApplicationDocumentsList from '@/modules/applications/components/view-application/documents/ApplicationDocumentsList.vue';

export default {
    name: 'ApplicationDocumentsTab',
    components: {
        ApplicationDocumentsList
    }
}
</script>