<template>
    <st-filters-pagination
        stateModule="applications/form"
        :filters="filters"
        :total="totalDocs"
        :header="header"
        :moreFilters="false"
        :showErase="false"
        :perPage="100"
    >

        <template #filters-toolbar>
            <slot name="toolbar"></slot>
        </template>

        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { FilterSchema } from '@/shared/form/filter-schema';
import { DocumentModel } from '@/modules/applications/models/document-model';

const { fields } = DocumentModel;

const filterSchema = new FilterSchema([
    fields.created_by,
    fields.status,
    fields.created_date
]);

export default {
    name: 'ApplicationDocumentsListFilter',
    props: {
        documentType: String,
        totalDocs: Number,
    },
    data() {
        return {
            filters: [], // use filterSchema in case filters will be used
            files: [],
        };
    },
    computed: {
        header() {
            if (this.documentType == 'input') {
                return this.$t('APPLICATION.LIST.DOCUMENT_CREATED_HEADER');
            }

            return this.$t('APPLICATION.LIST.DOCUMENT_RECEIVED_HEADER');
        },
    },
};
</script>
