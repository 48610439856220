<template>
    <div>
        <Loader v-if="copyFilesLoading" />
        <st-data-table
            :firstColumnSelectable="true"
            headClass="st-data-table-head first-column-check"
            @itemChecked="checkItems"
            :items="files"
            :fields="fields"
            :actions="actions"
            :per-page="100"
            :modelPresenter="presenter"
            :checkLimit="checkLimit ? appConfig.BULK_SIGN_LIMIT : 0"
            checkLimitMessage="APPLICATION.NOTIFIERS.DOC_RECEIVED_BULK_SIGN_LIMIT"
            :allowSelectAll="checkLimit"
            @edit="onEdit"
            @download="onDownload"
            @delete="onDelete"
            @view-doc="onViewDoc"
            @copy="onCopyFile"
            responsive="sm"
            hover
        >
        </st-data-table>

        <application-copy-file-modal
            ref="copy-file"
            @setFilename="saveCopiedFile"
        >
        </application-copy-file-modal>
    </div>
</template>

<script>
    import Message from '@/shared/message/message';
    import { mapActions, mapGetters } from 'vuex';
    import { DocumentModel } from '@/modules/applications/models/document-model';
    import ApplicationCopyFileModal from '@/modules/applications/components/view-application/documents/ApplicationCopyFileModal.vue';

    const { fields } = DocumentModel;

    export default {
        name: 'ApplicationDocumentsListTable',
        components: {
            ApplicationCopyFileModal
        },
        props: {
            documentType: String,
            isPublic: Boolean,
            checkLimit:  {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                presenter: DocumentModel.presenter,
                files: [],
                documentStatus: ['signed', 'sign_pending', 'sealed', 'seal_pending', 'seal_error'],
                finalizedStatus: ['rejected'],
                copyFilesLoading: false,
                fileToCopy: {}
            };
        },
        computed: {
            ...mapGetters({
                rows: 'applications/form/docs',
                currentUser: 'auth/currentUser',
                fileToken: 'applications/form/fileToken',
                record: 'applications/form/record',
                isStaff: 'auth/isStaff',
                uploadAccessToken: 'applications/form/uploadAccessToken',
                appConfig: "shared/appConfig"
            }),
            fields() {
                return [
                    {label: '', key: 'selected'},
                    fields.created_by,
                    fields.filename,
                    fields.created_date,
                    fields.status.extendField({ labelType: true }),
                ]
            },
            actions(){
                const actions = [
                    {
                        name: 'view-doc',
                        icon: 'file-contract',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW_ORIGINAL'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'download',
                        icon: 'file-download',
                        tooltipText: this.$t('GENERAL.ACTIONS.DOWNLOAD'),
                        customIcon: false,
                        type: 'primary'
                    },
                    {
                        name: 'delete',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        icon: 'trash-alt',
                        customIcon: false,
                        permission: this.isStaff,
                        customDeleteMsg: this.$t('APPLICATION.DELETE_CONFIRMATION'),
                        type: 'danger',
                        hideOnRow: true
                    }
                ];

                if (this.documentType === 'input') {
                    actions.unshift({
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        hideOnRow: true
                    });

                    actions.push({
                        name: 'copy',
                        tooltipText: this.$t('GENERAL.ACTIONS.COPY'),
                        icon: 'copy',
                        customIcon: false,
                        permission: this.isStaff,
                        type: 'primary',
                    });
                }

                return actions;
            },
        },
        methods: {
            ...mapActions({
                downloadDocument: 'applications/form/downloadDocument',
                getRelatedApplicationFiles: 'applications/form/findRelatedFiles',
                doRemoveApplicationDoc: 'applications/form/doRemoveApplicationDoc',
                downloadFile: 'shared/downloadFile',
                downloadFileContent: 'shared/downloadFileContent',
                doUpload: 'applications/form/upload',
                doUpdate: 'applications/form/update',
            }),
            onEdit(){
                this.$router.push({
                    name: 'editDocument'
                });
            },
            onDownload(doc){
                const docName = doc.item.filename;
                const fileName = docName.includes(".") ? docName : `${docName}.pdf`;
                const url = `${window.VUE_APP_API_URL}/files/${doc.item.file_id}`;
                this.downloadFile({ url, fileName, token: this.fileToken });
            },
            async onViewDoc(doc) {
                //find other solution to avoid 431 header error if token as param is too large
                const url = `${window.VUE_APP_API_URL}/files/${doc.item.file_id}`;
                const fileContent = await this.downloadFileContent({url, token: this.fileToken});

                const _url = window.URL.createObjectURL(fileContent);
                window.open(_url, "_blank").focus();

               // window.open(`${window.VUE_APP_API_URL}/files/${doc.item.file_id}?token=${this.fileToken}`, '_blank');
            },
            async refresh() {
                await this.combineFiles();
            },
            async combineFiles() {
                this.files = [];
                let allFiles = [];

                 //get related application files
                let relatedFiles = await this.getRelatedApplicationFiles(this.record.id);
                if (relatedFiles?.length) {
                    relatedFiles = relatedFiles.map((el) => el.files).reduce((acc, current) => [...acc, ...current], [])

                    let outputFiles = relatedFiles.filter((el) => el.file_type === 'output')
                        .map((el) => ({...el, hideSelectable: true}));
                    allFiles.push(...outputFiles);
                }

                //check for attachments
                if (this.record?.identification_files?.length) {
                    const attachments = this.record.identification_files.map(el => {
                        if (el.filename.split('.').pop().toLowerCase() === 'pdf') {
                            const hideSelectable = this.documentStatus.includes(el.status)
                                || this.finalizedStatus.includes(this.record.internal_status)
                                || !this.record?.assigned_staff_user_id;
                            return {...el, hideSelectable};
                        }

                        return {
                            ...el,
                            hideSelectable: true
                        }

                    });

                    allFiles.push(...attachments);
                }

                const appFiles = this.record.files.map((file) => {
                    if (this.finalizedStatus.includes(this.record.internal_status)
                        || !file.hasOwnProperty('is_public')
                        || !this.record?.assigned_staff_user_id)  {
                        return {...file, hideSelectable: true}
                    } else {
                        if (file.hasOwnProperty('is_public')) {
                            if (file.filename.split('.').pop().toLowerCase() === 'pdf') {
                                const hideSelectable = this.documentStatus.includes(file.status) ? true : false;
                                return {...file, hideSelectable};
                            } else {
                                return {...file, hideSelectable: true}
                            }
                        }
                    }
                });

                allFiles = appFiles.concat(allFiles);

                if (allFiles?.length && this.fileToken) {
                    allFiles.forEach(file => {
                        const {hideAction, hideActionName, ...cleanFile} = file;
                        if (cleanFile?.file_type === this.documentType) {
                            cleanFile.created_by = cleanFile?.created_by ?? 'system';
                            cleanFile.hideAction = true;

                            if (!cleanFile.hasOwnProperty('is_public')) {
                                if (this.documentType === 'input') {
                                    if (cleanFile?.status === 'error') {
                                        cleanFile.hideActionName = 'delete';
                                    } else {
                                        cleanFile.hideActionName = (cleanFile.created_by !== 'system') ? 'delete' : 'edit';
                                    }
                                }
                            } else {
                                cleanFile.hideActionName = 'delete';
                            }

                            this.files.push(cleanFile);
                        }
                    });
                }

                this.$emit("combined", this.files);
            },
            async onDelete(doc) {
                await this.doRemoveApplicationDoc({file_id: doc.item.file_id, app_id: this.record.id});
                await this.combineFiles();
                Message.success(this.$t('GENERAL.REMOVE_SUCCESS'));
            },
            checkItems(data) {
                this.$emit('selectedAttachments', data);
            },
            onCopyFile(data) {
                this.fileToCopy = data;
                const defaultFilename = `${this.$t('GENERAL.COPY_PREFIX')}_${data.item.filename}`;
                this.$refs['copy-file'].show(defaultFilename);
            },
            async saveCopiedFile(name) {
                this.copyFilesLoading = true;
                let originalFilename = this.fileToCopy.item.filename;

                const url = `${window.VUE_APP_API_URL}/files/${this.fileToCopy.item.file_id}`;
                const fileContent = await this.downloadFileContent({url, token: this.fileToken});

                this.copyFilesLoading = false;

                let extension = ".pdf";
                if (originalFilename.lastIndexOf('.') !== -1) {
                    extension = originalFilename.slice(originalFilename.lastIndexOf('.'));
                    originalFilename = originalFilename.slice(0, originalFilename.lastIndexOf('.'));
                }

                const filename = name !== '' ? name : originalFilename;
                const prefix = name !== '' ? '' : `${this.$t('GENERAL.COPY_PREFIX')}_`;
                let newFileName = `${prefix}${filename.replaceAll(' ', '_')}${extension}`;

                let file = new File([fileContent], newFileName, { type: fileContent.type })

                const formData = new FormData();
                formData.append('files', file);

                const uploadedFiles = await this.doUpload({
                    files: formData,
                    uploadAccessToken: this.uploadAccessToken,
                });

                const clonedFile = uploadedFiles.map((el) => ({
                    file_id: el.id,
                    filename: el.initialFilename,
                    created_date: el.created_date,
                    file_type: 'output',
                    created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                    is_public: false
                }));

                const payload = {
                    id: this.record.id,
                    loadStaff: true,
                    values: {
                        files: [
                            ...this.rows,
                            ...clonedFile,
                        ],
                    },
                };

                await this.doUpdate(payload);
                this.$emit('cloned');
            }
        },
        async created() {
           await this.combineFiles();
        },
    };
</script>
