<template>
    <div>
        <b-tabs class="st-tabs rounded-tabs" justified lazy>
            <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled">
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{ $t(tab.titleKey) }}</span>
                    </div>
                </template>
                <component
                    :is="tab.componentType"
                    :documentType="tab.documentType"
                    :disableUpload="tab.disableUpload"
                    :title="$t(tab.titleKey)"
                    v-if='!isLoading'
                >
                </component>
            </b-tab>
        </b-tabs>    
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ApplicationsPermissions } from "@/modules/applications/applications-permissions";
import ApplicationDocumentsCreated from './ApplicationDocumentsCreated';
import ApplicationDocumentsReceived from './ApplicationDocumentsReceived';
import ApplicationDocumentsCitizen from './ApplicationDocumentsCitizen';

export default {
    name: 'ApplicationDocumentsList',
    components: {
        ApplicationDocumentsCreated,
        ApplicationDocumentsReceived,
        ApplicationDocumentsCitizen,
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        isLoading() {
            return this.loading['applications/find'];
        },
        hasPermissionToOrganisationsApplication() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
        hasPermissionToStaffOwnApplications() {
            return new ApplicationsPermissions(this.currentUser).readOwnStaff;
        },
        hasPermissionToReadStaff() {
            return this.hasPermissionToOrganisationsApplication || this.hasPermissionToStaffOwnApplications;
        },
        tabs() {
            if (this.hasPermissionToReadStaff) {
                return [
                    {
                        index: 0,
                        name: 'application-detail',
                        titleKey: 'APPLICATION.TABS.DOCUMENT_CREATED',
                        componentType: 'ApplicationDocumentsCreated',
                        disabled: false,
                        documentType: 'input',
                        disableUpload: false,
                    },
                    {
                        index: 1,
                        name: 'application-detail',
                        titleKey: 'APPLICATION.TABS.DOCUMENT_REVIEW',
                        componentType: 'ApplicationDocumentsCreated',
                        disabled: false,
                        documentType: 'review',
                        disableUpload: true,
                    },
                    {
                        index: 2,
                        name: 'application-detail',
                        titleKey: 'APPLICATION.TABS.DOCUMENT_INTERNAL',
                        componentType: 'ApplicationDocumentsCreated',
                        disabled: false,
                        documentType: 'internal',
                        disableUpload: true,
                    },
                    {
                        index: 3,
                        name: 'application-documents',
                        titleKey: 'APPLICATION.TABS.DOCUMENT_RECEIVED',
                        componentType: 'ApplicationDocumentsReceived',
                        disabled: false,
                        documentType: 'output'
                    },
                ];
            }
            return [
                {
                    index: 1,
                    name: 'application-documents',
                    titleKey: 'APPLICATION.TABS.DOCUMENT_CREATED',
                    componentType: 'ApplicationDocumentsCitizen',
                    disabled: false,
                    documentType: 'input',
                },
                {
                    index: 2,
                    name: 'application-documents',
                    titleKey: 'APPLICATION.TABS.DOCUMENT_REVIEW',
                    componentType: 'ApplicationDocumentsCitizen',
                    disabled: false,
                    documentType: 'review',
                },
                {
                    index: 3,
                    name: 'application-documents',
                    titleKey: 'APPLICATION.TABS.DOCUMENT_RECEIVED',
                    componentType: 'ApplicationDocumentsCitizen',
                    disabled: false,
                    documentType: 'output',
                },
            ];
        }
    },
    created() {
    }
}
</script>