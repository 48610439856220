<template>
    <st-page :showHeader="false">
        <application-documents-list-filter :documentType="documentType" :totalDocs="allFiles.length">
            <template #toolbar>
                <application-documents-list-toolbar
                    :isPublic="true"
                    :canSign="canSign"
                    :fileIds="fileIds"
                    :allFiles="allFiles"
                    :disableUpload="disableUpload"
                    :documentType="documentType"
                    :title="title"
                    :canBulkCopy="true"
                    @refresh="refresh"
                />
            </template>

            <application-documents-list-table
                ref='application-documents-list-table'
                :documentType="documentType"
                :isPublic="true"
                :checkLimit="true"
                @selectedAttachments="selectedAttachments"
                @combined="combinedFiles"
                @cloned="refresh"/>
        </application-documents-list-filter>
    </st-page>
</template>

<script>
import ApplicationDocumentsListFilter from './ApplicationDocumentsListFilter';
import ApplicationDocumentsListTable from './ApplicationDocumentsListTable';
import ApplicationDocumentsListToolbar from './ApplicationDocumentsListToolbar';

export default {
    name: 'ApplicationDocumentsCreated',
    props: {
        documentType: String,
        disableUpload: {
            type: Boolean,
            default: false
        },
        title: String,
    },
    components: {
        ApplicationDocumentsListFilter,
        ApplicationDocumentsListTable,
        ApplicationDocumentsListToolbar
    },
    data() {
        return {
            canSign: false,
            fileIds: [],
            allFiles: [],
        }
    },
    computed: {},
    methods: {
        selectedAttachments(data) {
            this.canSign = data?.length > 0  && data?.length <= 5;
            this.fileIds = data;
        },
        combinedFiles(data) {
            this.allFiles = data;
        },
        refresh() {
            this.$refs['application-documents-list-table'].refresh();
        }
    },
}
</script>
